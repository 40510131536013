<template>
  <div>
    <el-form-item label="代码模板">
      <div>
        <CodeTemplate ref="templateEditor" :question="form" :readOnly="false" :hasTool="false" @input="getValue" />
      </div>
    </el-form-item>
    <el-form-item
      v-for="(item, index) in form.testcase"
      :label="`测试用例 ${index + 1}`"
      :key="item.index"
      :style="{ marginBottom: index === form.testcase.length - 1 ? '0' : '22px' }"
      :rules="{ required: true, message: '请输入选项 ', trigger: 'blur' }"
    >
      <div style="display: flex;">
        <p>输入</p>
        <el-input
          v-model="item.input"
          :class="{ error: hasError === index && item.input === '' }"
          style="width: 300px;margin: 0 20px 0 11px"
        ></el-input>
        <p>输出</p>
        <el-input
          v-model="item.output"
          :class="{ error: hasError === index && item.output === '' }"
          style="width: 300px;margin: 0 20px 0 11px"
        ></el-input>
        <el-button @click="handleRemove(index)" style="width:150px; height: 38px">删除</el-button>
      </div>
    </el-form-item>
    <el-form-item prop="testcase" style="height: 0" v-if="form.testcase.length > 0"></el-form-item>
    <el-form-item>
      <el-button type="primary" @click="handleAdd" style="width: auto">添加测试用例</el-button>
    </el-form-item>
  </div>
</template>

<script>
import CodeTemplate from '@components/manage/question/list/questions/Code'
import RichEditor from '@components/common/RichEditor'
export default {
  name: 'Code',
  components: {
    CodeTemplate,
    RichEditor
  },
  props: ['form'],
  data() {
    return {
      defaultText: '请输入测试用例',
      iconShow: false,
      hasError: ''
    }
  },
  methods: {
    handleAdd() {
      this.$emit('addTestcase')
    },
    handleRemove(index) {
      this.$emit('deleteTestcase', index)
    },
    handleInput(text, index, type) {
      this.$emit('handleTestcaseInput', text, index, type)
    },
    getValue(value, type) {
      this.$emit('handleTemplateInput', value, type)
    },
    setOptionError(index) {
      if (this.form.testcase[index].input === '') {
        this.$refs.inputEditor[index].changeColor('#FF5050')
      }
      if (this.form.testcase[index].output === '') {
        this.$refs.outputEditor[index].changeColor('#FF5050')
      }
    }
  }
}
</script>

<style scoped lang="less">
.codeEditor {
  width: 100%;
  border: solid 1px #e3e3e3;
}
.testCase {
  float: left;
  width: 319px;
  margin-left: 11px;
  margin-right: 20px;
}
.editor {
  height: 285px;
}
.error {
  ::v-deep .el-input__inner {
    border-color: #ff5050;
  }
}
</style>
