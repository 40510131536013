<template>
  <div>
    <el-form-item
      v-for="(item, index) in form.options"
      :label="`选项 ${$getChoiceIndex(index)}`"
      :rules="{ required: true }"
      :style="{ marginBottom: index === form.options.length - 1 ? '0' : '22px' }"
      :key="item.optionId"
    >
      <div class="option-wrapper choice">
        <RichEditor
          ref="optionInput"
          :value="form.options[index].content"
          :defaultText="defaultText"
          style="width: calc(100% - 170px)"
          :iconShow="iconShow"
          @input="handleInput($event, index)"
          @on-success="handleRemove(index)"
        />
        <el-radio class="set-correct-choice" v-model="item.correct" :label="true" @change="handleCheck($event, index)" border>设为正解</el-radio>
      </div>
    </el-form-item>
    <el-form-item prop="answer" style="height: 0" v-if="form.options.length > 0"></el-form-item>
    <el-form-item prop="options">
      <el-button type="primary" @click="handleAdd" v-if="form.options.length < 10">添加选项</el-button>
    </el-form-item>
  </div>
</template>

<script>
import RichEditor from '@components/common/RichEditor'
export default {
  name: 'SingleSelection',
  components: {
    RichEditor
  },
  props: ['form'],
  data() {
    return {
      iconShow: true,
      defaultText: '请输入正文'
    }
  },
  methods: {
    handleAdd() {
      if (this.form.options.length < 10) {
        this.$emit('addOption')
      }
    },
    handleRemove(index) {
      this.$emit('deleteOption', index)
    },
    handleInput(text, index) {
      this.$emit('handleOptionInput', text, index)
    },
    handleCheck(label, index) {
      this.$emit('handleCheckedOption', index)
    },
    setOptionError(index) {
      this.$refs.optionInput[index].changeColor('#FF5050')
    }
  }
}
</script>

<style scoped lang="less"></style>
