<template>
  <div>
    <el-form-item
      v-for="(item, index) in form.options"
      :label="`填空 ${index + 1}`"
      :key="item.index"
      :style="{ marginBottom: index === form.options.length - 1 ? '0' : '22px' }"
      :rules="{ required: true, message: '请输入选项 ', trigger: 'blur' }"
    >
      <el-input
        v-model="item.content"
        :placeholder="defaultText"
        :class="{ error: hasError === index && item.content === '' }"
        style="width: calc(100% - 170px);margin: 0 20px 0 0"
      ></el-input>
      <el-button @click="handleRemove(index)" style="width:150px; height: 38px">删除</el-button>
    </el-form-item>
    <el-form-item prop="answer" style="height: 0" v-if="form.options.length > 0"></el-form-item>
    <el-form-item prop="options">
      <el-button type="primary" @click="handleAdd">添加填空</el-button>
    </el-form-item>
  </div>
</template>

<script>
import RichEditor from '@components/common/RichEditor'
export default {
  name: 'FillInBlank',
  components: {
    RichEditor
  },
  props: ['form'],
  data() {
    return {
      defaultText: '一个空格如果有多个答案请用;隔开',
      iconShow: true,
      hasError: ''
    }
  },
  methods: {
    handleAdd() {
      if (this.form.options.length < 10) {
        this.$emit('addOption')
      }
    },
    handleInput(text, index) {
      this.$emit('handleOptionInput', text, index)
    },
    handleRemove(index) {
      this.$emit('deleteOption', index)
    },
    setOptionError(index) {
      this.hasError = index
    }
  }
}
</script>

<style scoped lang="less">
::v-deep .removeEditor {
  right: 10px;
}
.error {
  ::v-deep .el-input__inner {
    border-color: #ff5050;
  }
}
</style>
