<template>
  <div>
    <el-form-item label="答案" :rules="{ required: true }" style="margin-bottom: 0">
      <div>
        <RichEditor
          ref="optionInput"
          :defaultText="'请输入正文'"
          @input="handleInput"
          @validate="$emit('validateItem', 'answer')"
          style="width: 100%;"
        />
      </div>
    </el-form-item>
    <el-form-item prop="answer" style="height: 0"></el-form-item>
  </div>
</template>

<script>
import RichEditor from '@components/common/RichEditor'
export default {
  name: 'Answer',
  components: {
    RichEditor
  },
  props: ['form'],
  mounted() {
    this.$refs.optionInput.modifyEditor(this.form.answer)
  },
  methods: {
    handleInput(text) {
      this.$emit('handleAnswerInput', text)
    },
    setOptionError() {
      this.$refs.optionInput.changeColor('#FF5050')
    }
  }
}
</script>

<style scoped lang="less"></style>
