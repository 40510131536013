let mk_block = function(block, trail, line) {
  // Be helpful for default case in tests.
  if (arguments.length === 1) trail = '\n\n'
  // 去掉粘贴到的首尾空格
  block = block.replace(/(^(&nbsp;\s*)*)|((&nbsp;\s*)*$)/g, '')
  let s = String(block)
  s.trailing = trail
  // To make it clear its not just a string
  // s.inspect = mk_block_inspect;
  // s.toSource = mk_block_toSource;

  if (line !== undefined) {
    s.lineNumber = line
  }
  // 这里是将每个块域的首字母大写
  s = s.substr(0, 1).toUpperCase() + s.substr(1, s.length - 1)
  // 这里将中文的顿号自动替换为英文状态下的点
  return s.replace(/^([A-J]|\d+)[、|．]/, '$1.')
}

let forEach1 = function(arr, cb, thisp) {
  for (let i = 0; i < arr.length; i++) {
    cb.call(thisp || arr, arr[i], i, arr)
  }
}

const dialectBlock = {
  // 默认类型 0
  _type: 0,
  // 兼容老版本  需过滤 1.[题型]
  _oldReg: /\s*(\[.{3}])\s*/i,
  // _titleReg: /^\s*(([0-9]+\.)|(((\()|（)[0-9]+((\))|）)))\s*(.*?)\s*(?:\n|$)/,
  _titleRegs: [
    /^\s*([0-9]+)\s*\.\s*(.*?)\s*(?:\n|$)/,
    /^\s*([0-9]+)\s*。\s*(.*?)\s*(?:\n|$)/,
    /^\s*（?([0-9]+)\s*）\s*(.*?)\s*(?:\n|$)/,
    /^\s*\(?([0-9]+)\s*\)\s*(.*?)\s*(?:\n|$)/,
    /^\s*([0-9]+)\s*:\s*(.*?)\s*(?:\n|$)/,
    /^\s*([0-9]+)\s*：\s*(.*?)\s*(?:\n|$)/,
    /^\s*([0-9]+)\s*、\s*(.*?)\s*(?:\n|$)/,
    /^\s*第([0-9]+)\s*题\s*(.*?)\s*(?:\n|$)/
  ],
  _titleReg: /^\s*([0-9]+)\s*\.\s*(.*?)\s*(?:\n|$)/,
  _keyRegs: [
    /^\s*([A-J])([.])\s*(.*?)\s*(?:\n|$)/i,
    /^\s*([A-J])([、])\s*(.*?)\s*(?:\n|$)/i,
    /^\s*([A-J])([,])\s*(.*?)\s*(?:\n|$)/i,
    /^\s*([A-J])([)])\s*(.*?)\s*(?:\n|$)/i,
    /^\s*([A-J])([:])\s*(.*?)\s*(?:\n|$)/i,
    /^\s*([A-J])([：])\s*(.*?)\s*(?:\n|$)/i,
    /^\s*\[?([A-J])([\]])\s*(.*?)\s*(?:\n|$)/i
  ],
  _keyReg: /^(\s*)?([A-J])([.、,])\s*(.*?)\s*(?:\n|$)/i,
  _keyDelimiters: [
    /(?=\s+[A-J]\.)/i,
    /(?=\s+[A-J]、)/i,
    /(?=\s+[A-J],)/i,
    /(?=\s+[A-J]\))/i,
    /(?=\s+[A-J]:)/i,
    /(?=\s+[A-J]：)/i,
    /(?=\s+\[?[A-J]])/i
  ],
  nameReg: /^\n?\s*(([0-9]+\s*[.|、])|(((\()|（)[0-9]+((\))|）)))\s*(.*?)\s*(?:\n|$)/g,
  answerReg: /^\n?\s*(答案[:：])\s*(.*?)\s*(?:\n|$)/g,
  fillReg: /([(|（]\s*[)|）])/g,
  rightReg: /^(正确|对|√|是|T)$/gi,
  wrongReg: /^(错误|错|×|否|F)$/gi,
  typeNames: ['判断题', '单选题', '多选题', '填空题', '简答题', '代码题', '未识别'],
  difficultyNames: ['简单', '普通', '困难'],
  optionKeys: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
  titleImgReg: /\|\|img(\d+)\|\|/g,

  qts: [],
  cks: [],

  /** 类型校验 */
  // 判断是不是题目
  ckTitle: function(block, flag) {
    for (let i in this._titleRegs) {
      let tmpTitleReg = this._titleRegs[i]
      let m = block.match(tmpTitleReg)
      if (m) {
        return flag ? m : true
      }
    }
    return false
  },

  // 判断是不是 [A - J] 的选项
  ckKey: function(block, flag) {
    for (let i in this._keyRegs) {
      let tmpKeyReg = this._keyRegs[i]
      let m = block.match(tmpKeyReg)
      if (m) {
        return flag ? m : true
      }
    }
    return false
  },

  // 判断是不是答案
  ckAnswer: function(block, flag) {
    // 替换中文中括号
    block = block.replace(/^\s*【\s*答案\s*】\s*/, ' 答案：')
    // 替换英文中括号
    block = block.replace(/^\s*\[\s*答案\s*]\s*/, ' 答案：')
    let m = block.match(/^\s*(答案[:：])\s*(.*?)\s*(?:\n|$)/)
    return !m ? false : flag ? m : true
  },

  // 判断是不分析
  ckAnalysis: function(block, flag) {
    block = block.replace(/^\s*【\s*解析\s*】\s*/, '解析：')
    block = block.replace(/^\s*\[\s*解析\s*]\s*/, '解析：')
    let m = block.match(/^\s*(解析[:：])\s*(.*?)\s*(?:\n|$)/)
    return !m ? false : flag ? m : true
  },

  // 判断是不是难度
  ckDifficult: function(block, flag) {
    block = block.replace(/^\s*【\s*难度\s*】\s*/, '难度：')
    block = block.replace(/^\s*\[\s*难度\s*]\s*/, '难度：')
    let m = block.match(/^\s*(难度[:：])\s*(.*?)\s*(?:\n|$)/)
    // console.log(m)
    return !m ? false : flag ? m : true
  },

  // 判断是不是分数
  ckFraction: function(block, flag) {
    block = block.replace(/^\s*【\s*分数\s*】\s*/, '分数：')
    block = block.replace(/^\s*\[\s*分数\s*]\s*/, '分数：')
    let m = block.match(/^\s*(分数[:：])\s*(.*?)\s*(?:\n|$)/)
    return !m ? false : flag ? m : true
  },

  // 判断是不是标签
  ckLabel: function(block, flag) {
    block = block.replace(/^\s*【\s*试题标签\s*】\s*/, '试题标签：')
    let m = block.match(/^\s*(试题标签[:：])\s*(.*?)\s*(?:\n|$)/)
    return !m ? false : flag ? m : true
  },

  // 判断是不是问答题的 关键字
  ckSkeyword: function(block, flag) {
    block = block.replace(/^\s*【\s*标签\s*】\s*/, '标签：')
    block = block.replace(/^\s*\[\s*标签\s*]\s*/, '标签：')
    let m = block.match(/^\s*(标签[:：])\s*(.*?)\s*(?:\n|$)/)
    return !m ? false : flag ? m : true
  },

  // 判断是不是代码题的测试用例
  ckTestcase: function(block, flag) {
    block = block.replace(/^\s*【\s*测试用例\s*】\s*/, '测试用例：')
    block = block.replace(/^\s*\[\s*测试用例\s*]\s*/, '测试用例：')
    let m = block.match(/^\s*(测试用例[:：])\s*(.*?)\s*(?:\n|$)/)
    return !m ? false : flag ? m : true
  },

  // 校验是否符合规则
  validate: function(block) {
    let that = this
    let res = false
    that.cks.forEach(function(val, index) {
      res = res || that[val](block)
    })
    return res
  },

  /** 自定义每题的题干、选项、答案和解析 */
  // 这是题干部分
  qtTitle: function qtTitle(block, next) {
    block = block.replace(this._oldReg, '')
    let m = this.ckTitle(block, true)
    if (!m) return []

    let title = ['title']
    // 全部
    Array.prototype.push.apply(title, this._processInline(m[0]))
    // 题目
    Array.prototype.push.apply(title, this._processInline(m[2]))
    // 序号
    Array.prototype.push.apply(title, this._processInline(m[1]))

    // if (m[0].length < block.length)
    // 	next.unshift(mk_block(block.substr(m[0].length), block.trailing, block.lineNumber + 2));

    return [title]
  },

  //这是选项部分
  qtKey: function qtKey(block, next) {
    let xxx = this.ckKey(block, true)
    // let m = block.match(/^\s*([A-J])(\.)\s*(.*?)\s*(?:\n|$)/i);
    if (!xxx) return []

    let options = this.parseOption(block)

    let newKeys = []
    for (let j in options) {
      let m = this.ckKey(options[j], true)
      if (!m) {
        continue
      }
      // console.log('m', m)
      //
      let type = ''
      let n = m[0].match(/[A-J]/i)
      let upN = n[0].toLocaleUpperCase()
      // console.log('upN', upN)
      switch (upN) {
        case 'A':
          type = 'A'
          break
        case 'B':
          type = 'B'
          break
        case 'C':
          type = 'C'
          break
        case 'D':
          type = 'D'
          break
        case 'E':
          type = 'E'
          break
        case 'F':
          type = 'F'
          break
        case 'G':
          type = 'G'
          break
        case 'H':
          type = 'H'
          break
        case 'I':
          type = 'I'
          break
        case 'J':
          type = 'J'
          break
        default:
          type = 'Error'
      }
      let key = ['key' + type]

      // 全部
      Array.prototype.push.apply(key, this._processInline(m[0]))
      // 选项内容
      Array.prototype.push.apply(key, this._processInline(m[3]))
      // 选项字母
      Array.prototype.push.apply(key, this._processInline(m[1]))

      // if (m[0].length < block.length)
      // 	next.unshift(mk_block(block.substr(m[0].length), block.trailing, block.lineNumber + 2));

      newKeys.push(key)
    }
    // console.log('newKeys', newKeys)
    return newKeys
  },

  //普通标签部分
  qtCommon: function qtCore(block, next) {
    block = block.replace(/^\s*【\s*普通标签\s*】\s*/, '普通标签:')
    let m = block.match(/^\s*(\s*普通标签[:：]\s*)\s*(.*?)\s*(?:\n|$)/)
    if (!m) return []

    let comKeyWord = ['comKeyWord']
    Array.prototype.push.apply(comKeyWord, this._processInline(m[0]))
    Array.prototype.push.apply(comKeyWord, this._processInline(m[2]))

    // if (m[0].length < block.length)
    // 	next.unshift(mk_block(block.substr(m[0].length), block.trailing, block.lineNumber + 2));

    return [comKeyWord]
  },

  //核心标签部分
  qtCore: function qtCore(block, next) {
    block = block.replace(/^\s*【\s*核心标签\s*】\s*/, '核心标签:')
    let m = block.match(/^\s*(\s*核心标签[:：]\s*)\s*(.*?)\s*(?:\n|$)/)
    if (!m) return []

    let coreKeyWord = ['coreKeyWord']
    Array.prototype.push.apply(coreKeyWord, this._processInline(m[0]))
    Array.prototype.push.apply(coreKeyWord, this._processInline(m[2]))

    // if (m[0].length < block.length)
    // 	next.unshift(mk_block(block.substr(m[0].length), block.trailing, block.lineNumber + 2));

    return [coreKeyWord]
  },

  // 这是答案部分
  qtAnswer: function qtAnswer(block, next) {
    // 替换中文中括号
    block = block.replace(/^\s*【\s*答案\s*】\s*/, ' 答案：')
    // 替换英文中括号
    block = block.replace(/^\s*\[\s*答案\s*]\s*/, ' 答案：')
    let m = block.match(/^\s*(答案[:：])\s*(.*?)\s*(?:\n|$)/)
    // console.log(m)
    if (!m) return []
    let answer = ['answer']
    // 全部
    Array.prototype.push.apply(answer, this._processInline(m[0]))
    // 内容
    Array.prototype.push.apply(answer, this._processInline(m[2]))
    //
    // if (m[0].length < block.length)
    // 	next.unshift(mk_block(block.substr(m[0].length), block.trailing, block.lineNumber + 2));
    //
    return [answer]
  },

  // 这是解析部分
  qtAnalysis: function qtAnalysis(block, next) {
    block = block.replace(/^\s*【\s*解析\s*】\s*/, '解析：')
    block = block.replace(/^\s*\[\s*解析\s*]\s*/, '解析：')
    let m = block.match(/^\s*(解析[:：])\s*(.*?)\s*(?:\n|$)/)
    if (!m) return []
    let analysis = ['analysis']
    Array.prototype.push.apply(analysis, this._processInline(m[0]))
    Array.prototype.push.apply(analysis, this._processInline(m[2]))

    // if (m[0].length < block.length)
    // 	next.unshift(mk_block(block.substr(m[0].length), block.trailing, block.lineNumber + 2));

    return [analysis]
  },

  // 这是分数部分
  qtFraction: function qtAnalysis(block, next) {
    block = block.replace(/^\s*【\s*分数\s*】\s*/, '分数：')
    block = block.replace(/^\s*\[\s*分数\s*]\s*/, '分数：')
    let m = block.match(/^\s*(分数[:：])\s*(.*?)\s*(?:\n|$)/)
    if (!m) return []
    let fraction = ['fraction']
    Array.prototype.push.apply(fraction, this._processInline(m[0]))
    Array.prototype.push.apply(fraction, this._processInline(m[2]))

    // if (m[0].length < block.length)
    // 	next.unshift(mk_block(block.substr(m[0].length), block.trailing, block.lineNumber + 2));

    return [fraction]
  },

  // 这是难度部分
  qtDifficult: function qtDifficult(block, next) {
    block = block.replace(/^\s*【\s*难度\s*】\s*/, '难度：')
    block = block.replace(/^\s*\[\s*难度\s*]\s*/, '难度：')
    let m = block.match(/^\s*(难度[:：])\s*(.*?)\s*(?:\n|$)/)
    // console.log(m)
    if (!m) return []
    let difficult = ['difficult']
    Array.prototype.push.apply(difficult, this._processInline(m[0]))
    Array.prototype.push.apply(difficult, this._processInline(m[2]))

    // if (m[0].length < block.length) next.unshift(mk_block(block.substr(m[0].length), block.trailing, block.lineNumber + 2))

    return [difficult]
  },

  // 这是标签部分
  qtLabel: function qtLabel(block, next) {
    block = block.replace(/^\s*【\s*试题标签\s*】\s*/, '试题标签：')
    let m = block.match(/^\s*(试题标签[:：])\s*(.*?)\s*(?:\n|$)/)
    if (!m) return []
    let label = ['label']
    Array.prototype.push.apply(label, this._processInline(m[0]))
    Array.prototype.push.apply(label, this._processInline(m[2]))
    // if (m[0].length < block.length)
    // 	next.unshift(mk_block(block.substr(m[0].length), block.trailing, block.lineNumber + 2));
    return [label]
  },

  // 这是标签部分
  qtKword: function qtLabel(block, next) {
    block = block.replace(/^\s*【\s*标签\s*】\s*/, '标签：')
    block = block.replace(/^\s*\[\s*标签\s*]\s*/, '标签：')
    let m = block.match(/^\s*(标签[:：])\s*(.*?)\s*(?:\n|$)/)
    if (!m) return []
    let kword = ['kword']
    Array.prototype.push.apply(kword, this._processInline(m[0]))
    Array.prototype.push.apply(kword, this._processInline(m[2]))
    // if (m[0].length < block.length)
    // 	next.unshift(mk_block(block.substr(m[0].length), block.trailing, block.lineNumber + 2));
    return [kword]
  },

  //测试用例
  qtTestcase: function qtTestcase(block, next) {
    block = block.replace(/^\s*【\s*测试用例\s*】\s*/, '测试用例：')
    block = block.replace(/^\s*\[\s*测试用例\s*]\s*/, '测试用例：')
    let m = block.match(/^\s*(测试用例[:：])\s*(.*?)\s*(?:\n|$)/)
    // console.log(m)
    if (!m) return []
    let testcase = ['testcase']
    Array.prototype.push.apply(testcase, this._processInline(m[0]))
    Array.prototype.push.apply(testcase, this._processInline(m[2]))
    return [testcase]
  },

  //预计时间
  qtExpectedTime: function qtExpectedTime(block, next) {
    block = block.replace(/^\s*【\s*预期时间\s*】\s*/, '预期时间：')
    block = block.replace(/^\s*\[\s*预期时间\s*]\s*/, '预期时间：')
    let m = block.match(/^\s*(预期时间[:：])\s*(.*?)\s*(?:\n|$)/)
    // console.log(m)
    if (!m) return []
    let expectedTime = ['expectedTime']
    Array.prototype.push.apply(expectedTime, this._processInline(m[0]))
    Array.prototype.push.apply(expectedTime, this._processInline(m[2]))
    return [expectedTime]
  },

  // 其他类型
  qtPara: function para(block, next) {
    // everything's a para!
    let para = this._processInline(block)
    return [['para'].concat(para).concat(para)]
  },

  // 基本处理方法
  _oneElement: function(text, patterns_or_re, previous_nodes) {
    let m,
      res,
      lastIndex = 0
    patterns_or_re = patterns_or_re || this.__patterns__
    let re = new RegExp('([\\s\\S]*?)(' + (patterns_or_re.source || patterns_or_re) + ')')

    m = re.exec(text)
    if (!m) {
      // Just boring text
      return [text.length, text]
    } else if (m[1]) {
      // Some un-interesting text matched. Return that first
      return [m[1].length, m[1]]
    }
    res = [text.length, text]
    return res
  },

  _processInline: function(text) {
    if (!text) {
      return ['']
    }
    let out = [],
      res
    let patterns = '(?:  \\n)'

    function add(x) {
      //D:self.debug("  adding output", uneval(x));
      if (typeof x == 'string' && typeof out[out.length - 1] == 'string') out[out.length - 1] += x
      else out.push(x)
    }

    while (text.length > 0) {
      res = this._oneElement(text, patterns, out)
      text = text.substr(res.shift())
      forEach1(res, add)
    }
    return out
  },

  // 计算行数
  countlines: function(str, blocks) {
    let n = 0,
      i = -1
    while ((i = str.indexOf('\n', i + 1)) !== -1) n++
    // 连续两次或以上换行，视为两道题的分割线
    // if (n>1) blocks.push("-------分割线-------");
    return n
  },
  // 切割
  splitBlocks: function(lines, startLine) {
    let that = this
    let input = lines.join('\n  ')
    // 处理换行
    input = input.replace(/(\r\n|\n|\r)/g, '\n')
    // 这里是判断若选项位于同一行，则自动转行，判断依据是选项前有空格且选项后有点或顿号
    // 否则视为同一选项中的内容
    let n
    let res = /(\s)+\[?([a-j])([、，,．。)）:：\]])/gi

    // console.log('input1', input);
    input = input.replace(res, '\n$2$3')
    // console.log('input2', input);

    // [\s\S] matches _anything_ (newline or space)
    // [^] is equivalent but doesn't work in IEs.
    // 这是原先用的正则，一句话结束换行一次不会分割成块
    // let re = /([\s\S]+?)($|\n#|\n(?:\s*\n|$)+)/g,
    // 两行中间没有空格也会显示成<p></p><p></p>
    let re = /([\s\S]+?)($|\n#|\n(?:\s*\n|$)*)/g,
      blocks = [],
      m
    let line_no = 1

    if ((m = /^(\s*\n)/.exec(input)) != null) {
      // skip (but count) leading blank lines
      line_no += that.countlines(m[0], blocks)
      re.lastIndex = m[0].length
    }
    while ((m = re.exec(input)) !== null) {
      if (m[2] === '\n#') {
        m[2] = '\n'
        re.lastIndex--
      }
      blocks.push(mk_block(m[1], m[2], line_no))
      line_no += that.countlines(m[0], blocks)
    }
    // console.log(blocks)
    return blocks
  },
  // 解析题目区块
  processBlock: function(block, next, qt_type) {
    let that = this
    // 考题类型
    that._type = qt_type
    // 遍历匹配各个类型
    for (let i = 0; i < that.qts.length; i++) {
      let res = that[that.qts[i]](block, next)
      // 匹配，直接返回
      if (res && Array.isArray(res) && res.length > 0) {
        return res
      }
    }
    // 都没有匹配上
    return []
  },

  // 对外解析
  parse: function(lines) {
    let type = this.parseType(lines)
    // console.log('type', type)
    let questionBlocks = this.parseQuestion(lines, type)
    // console.log('questionBlocks', questionBlocks);
    return this.packQuestion(questionBlocks, type)
  },
  parseOptions: function(lines) {
    // console.log('lineslineslineslineslines', lines)
    let that = this
    let optionLines = []
    lines.forEach(function(ele, i) {
      // console.log('ele', ele)
      if (that.ckKey(ele)) {
        let newOptionLines = that.parseOption(ele)
        // console.log('newOptionLines', newOptionLines)

        optionLines = optionLines.concat(newOptionLines)
      }
    })
    // console.log('optionLinesoptionLinesoptionLinesoptionLines', optionLines)

    return optionLines
  },
  parseOption: function(line) {
    let newArr = []
    for (let i in this._keyRegs) {
      let tmpKeyReg = this._keyRegs[i]
      let m = line.match(tmpKeyReg)
      if (m) {
        //
        let delimiter = this._keyDelimiters[i]
        // console.log('delimiter', delimiter)

        let newKeys = line.split(new RegExp(delimiter))
        // console.log('newKeys', newKeys)

        for (let j in newKeys) {
          let tmp = newKeys[j].trim()
          if (tmp.length > 0) {
            newArr.push(tmp)
          }
        }
      }
    }
    return newArr
  },
  // 解析考题类型
  parseType: function(lines) {
    let newString = lines.join(' ')
    let first = newString.split('答案')[0]
    return this.typeNames.findIndex(item => {
      return first.includes(item)
    })
  },
  // 获取（）|()数量
  getBracketsCount: function(text) {
    let emptyReg1 = /__[1-9]__/gi
    // let emptyReg2 = /（[\s]*?）/gi
    let m1 = text.match(emptyReg1)
    // let m2 = text.match(emptyReg2)
    let total = 0
    total += m1 ? m1.length : 0
    // total += m2 ? m2.length : 0
    return total
  },
  // 解析考题
  parseQuestion: function(lines, qt_type) {
    // console.log(lines)
    let that = this
    let blocks
    // console.log(lines)
    if (lines instanceof Array) {
      blocks = lines
    } else {
      blocks = that.splitBlocks(lines)
    }
    // console.log(blocks)
    // console.log('blocks', blocks)
    let tree = []
    let index = -1
    while (blocks.length) {
      let b = that.processBlock(blocks.shift(), blocks, qt_type)
      // console.log('bbbbbbbbbbbb', b)
      if (!b.length) continue
      for (let k in b) {
        // console.log('index', index)
        let blockType = b[k][0]
        // console.log('blockType', blockType)

        // console.log('blockType', blockType)
        // 记录上一次正常数据, 如果是para, 则添加到上一次数据上
        if (blockType === 'para') {
          // console.log('tree', tree)

          let lastb = tree[index]
          // console.log('lastb', lastb)

          if (lastb[4]) {
            lastb[4].push(b[k][2])
          } else {
            let tmpArr = []
            tmpArr.push(b[k][2])
            lastb[4] = tmpArr
          }
          tree[index] = lastb
        } else {
          index++
          // 默认无额外数据
          b[k][4] = []
          tree.push.apply(tree, [b[k]])
        }
      }
    }
    // console.log('tree', tree)
    return tree
  },
  // 获取难度
  parseDifficulty: function(text) {
    text = text ? text : ''
    let res
    if (text) {
      let index = this.difficultyNames.indexOf(text)
      if (index > -1) {
        res = {
          name: text,
          index: index + 1
        }
      } else {
        res = {
          name: text,
          index: 0
        }
      }
    } else {
      res = {
        name: '简单',
        index: 3
      }
    }
    return res
  },
  // 打包考题数据结构
  packQuestion: function(blocks, qt_type) {
    let that = this
    let question = {
      // 题型
      type: qt_type,
      // 题型名称
      typeName: that.typeNames[qt_type],
      // 行数
      lineCount: blocks.length
    }

    let topicValue = ''
    let optionValues = []
    let optionIndexs = []
    let options = []

    // console.log('blocks', blocks)

    let uniqueOptionValues = []
    // console.log(blocks)
    blocks.forEach(item => {
      // console.log(item)
      let blockType = item[0]
      let blockContent = item[1]
      let blockValue = item[2]
      let blockExtra = that.checkUndefined(item[3])
      let blockUnparse = that.checkUndefined(item[4])

      let blockUnpType = blockType + '_unp'
      let allEnReg = new RegExp('^[a-zA-Zs.]+$')
      let newBlockUnparse = []
      let blockExtraEn = true

      if (blockUnparse) {
        // console.log('blockUnparse', blockUnparse)
        let newBlockUnparse = []
        for (let i in blockUnparse) {
          let tmp = blockUnparse[i].replace(/\s*/g, '')
          if (tmp.length > 0) {
            blockExtraEn = blockExtraEn && allEnReg.test(tmp)
            newBlockUnparse.push(tmp)
          }
        }
        // console.log('newBlockUnparse', newBlockUnparse)
        question[blockUnpType] = newBlockUnparse
      } else {
        question[blockUnpType] = []
      }

      // 题目
      if (blockType.indexOf('title') > -1) {
        // question[blockType] = blockValue.replace(/\s/g, "");
        question[blockType] = blockValue
        question['order'] = blockExtra
        topicValue = blockValue

        let titleImgReg = /\|\|img(\d+)\|\|/g
        // 题目支持多行
        let tmpTitleExtra = (question['title_extra'] = question[blockUnpType])
        let titleEles = ['<p>' + blockValue + '</p>']
        let titleExtra = []

        for (let i in tmpTitleExtra) {
          if (!titleImgReg.test(tmpTitleExtra[i])) {
            titleEles.push('<p>' + tmpTitleExtra[i] + '</p>')
            titleExtra.push(tmpTitleExtra[i])
          }
        }

        let titleAll = topicValue + titleExtra.join('')
        question['title_all'] = titleAll.replace(/\s/g, '')

        question['title_eles'] = titleEles

        question[blockUnpType] = []
      }

      // 选项
      if (blockType.indexOf('key') > -1) {
        // 选择多行内容，合并
        if (question[blockUnpType]) {
          blockValue += question[blockUnpType].join('')
          question[blockUnpType] = []
        }

        // 选项是否存在
        if (question[blockType]) {
          question[blockType] = blockValue
          // 覆盖
          let i = uniqueOptionValues.indexOf(blockType)
          options[i].content = blockValue
          optionValues[i] = blockValue
        } else {
          // ABCD 数组
          optionIndexs.push(blockExtra.toLocaleUpperCase())
          // va1,val2,value3 数组
          optionValues.push(blockValue)
          options.push({
            content: blockValue,
            correct: false,
            index: options.length
          })
          uniqueOptionValues.push(blockType)
          // key->value
        }
        question[blockType] = blockValue
      }

      // 难度
      if (blockType.indexOf('difficult') > -1) {
        question[blockType] = blockValue
      }
      // 答案
      if (blockType.indexOf('answer') > -1) {
        question[blockType] = blockValue
      }
      // 解析
      if (blockType.indexOf('analysis') > -1) {
        question[blockType] = blockValue
      }
      // 标签
      if (blockType.indexOf('kword') > -1) {
        question[blockType] = blockValue
      }

      // 测试用例
      if (blockType.indexOf('testcase') > -1) {
        question[blockType] = blockValue
      }

      //预期时间
      if (blockType.indexOf('expectedTime') > -1) {
        question[blockType] = blockValue
      }
    })

    question.optionIndexs = optionIndexs
    question.optionValues = optionValues
    question.optionCount = options.length
    question.options = options.map(item => {
      if (question.answer && question.answer.includes(optionIndexs[item.index])) {
        item.correct = true
      }
      return item
    })
    question.difficult = question.difficult ? (question.difficult.indexOf('undefined') > -1 ? '' : question.difficult) : ''
    question.expectedTime = question.expectedTime
      ? question.expectedTime.indexOf('undefined') > -1 || question.expectedTime.includes('若不填写自动设置为')
        ? '1分钟'
        : question.expectedTime
      : '1分钟'
    return question
  },
  checkUndefined: function(txt) {
    return txt ? (txt.indexOf('undefined') > -1 ? '' : txt) : ''
  },

  // 获取唯一数组
  getUniqueArray: function(answers) {
    if (answers.length < 1) {
      return []
    }
    return answers.filter(function(element, index, self) {
      return self.indexOf(element) === index
    })
  }
}

let buildBlockOrder = function(d) {
  let qts = []
  let cks = []
  let i
  for (i in d) {
    if (i.indexOf('qt') === 0 && i !== 'qts') {
      qts.push(i)
    }
    if (i.indexOf('ck') === 0 && i !== 'cks') {
      cks.push(i)
    }
  }
  d.qts = qts
  d.cks = cks
}

buildBlockOrder(dialectBlock)

export { dialectBlock }
