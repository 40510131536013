<template>
  <div>
    <el-form-item
      v-for="(item, index) in form.options"
      :label="`选项 ${$getChoiceIndex(index)}`"
      :style="{ marginBottom: index === form.options.length - 1 ? '0' : '22px' }"
      :rules="{ required: true }"
      :key="item.optionId"
    >
      <div class="option-wrapper choice">
        <RichEditor
          ref="optionInput"
          :value="form.options[index].content"
          :defaultText="defaultText"
          style="width: calc(100% - 170px)"
          :iconShow="iconShow"
          @input="handleInput($event, index)"
          @on-success="handleRemove(index)"
        />
        <el-checkbox v-model="item.correct" label="正确答案" class="set-correct-choice" border></el-checkbox>
      </div>
    </el-form-item>
    <el-form-item prop="answer" style="height: 0" v-if="form.options.length > 0"></el-form-item>
    <el-form-item prop="options">
      <el-button type="primary" @click="handleAdd" v-if="form.options.length < 10">添加选项</el-button>
    </el-form-item>
  </div>
</template>

<script>
import RichEditor from '@components/common/RichEditor'
import YTIcon from '@components/common/YTIcon'
export default {
  name: 'SingleSelection',
  components: {
    RichEditor,
    YTIcon
  },
  props: ['form'],
  data() {
    return {
      iconShow: true,
      defaultText: '请输入正文'
    }
  },
  methods: {
    handleAdd() {
      if (this.form.options.length < 10) {
        this.$emit('addOption')
      }
    },
    handleRemove(index) {
      this.$emit('deleteOption', index)
    },
    handleInput(text, index) {
      this.$emit('handleOptionInput', text, index)
    },
    setOptionError(index) {
      this.$refs.optionInput[index].changeColor('#FF5050')
    }
  }
}
</script>

<style scoped lang="less">
.trueChoice {
  position: absolute;
  right: 0;
  top: 0;
  width: 150px;
  height: 38px;
  padding-left: 33px;
}
::v-deep .el-checkbox__input {
  line-height: 17px;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background: #448bff;
  border-color: #448bff;
}
::v-deep .el-button--primary {
  border-color: #448bff;
}
</style>
