import config from '../config/config'
import { axios } from './index'

const questionBankUrl = config.baseUrl + '/question/api/v1/questionBanks'

export default {
  //添加题库
  add(payload) {
    return axios.post(`${questionBankUrl}/add/saas`, payload)
  },
  //编辑题库
  update(payload, id) {
    return axios.post(`${questionBankUrl}/modify/saas?id=${id}`, payload)
  },
  //分页搜索题库
  search(payload, pageNum, pageSize) {
    return axios.post(`${questionBankUrl}/search/saas?page=${pageNum}&size=${pageSize}`, payload)
  },
  //批量删除题库
  batchDelete(payload) {
    return axios.post(`${questionBankUrl}/delete-by-ids`, payload)
  },
  //通过题目名称,题目标签,关键词搜索
  searchByForm(payload) {
    return axios.post(`${questionBankUrl}/search/detail`, payload)
  },
  //搜索题库列表（简要信息）
  searchSimpleInfo() {
    return axios.get(`${questionBankUrl}/search/saas/simple`)
  },
  //搜索题库列表(判断权限
  searchSimpleInfoByPermission() {
    return axios.post(`${questionBankUrl}/search/all/simple`)
  },
  //获取试卷下对应的题目数量
  getQuestionCountByPaperId(payload) {
    return axios.post(`${questionBankUrl}/get/paper/question-bank/question/count`, payload)
  }
}
